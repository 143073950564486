import React, { useState, useEffect } from "react"
import { useIntl, Link, changeLocale } from "gatsby-plugin-intl"

// Icons
import {
  AiFillHome,
  AiFillGithub,
  AiOutlineUnorderedList,
} from "react-icons/ai"
import { BsFillPersonFill } from "react-icons/bs"
import { VscTools } from "react-icons/vsc"
import { BiShieldQuarter } from "react-icons/bi"
import { GrLanguage } from "react-icons/gr"

//Styles
import layoutStyles from "./layout.module.scss"

const Layout = ({ children }) => {
  const intl = useIntl()
  const [date, setDate] = useState(new Date())
  useEffect(() => {
    setTimeout(() => {
      setDate(new Date())
    }, (60 - new Date().getSeconds) * 1000)

    /*eslint-disable-next-line*/
    const updateDate = () => {
      setDate(new Date())
      setTimeout(updateDate, 60000)
    }
    return () => {
      window.clearTimeout()
    }
  })
  const [startMenuOpen, setStartMenuOpen] = useState(false)
  const closeMenus = () => {
    setStartMenuOpen(false)
    setLocaleMenu(false)
  }
  const [localeMenu, setLocaleMenu] = useState(false)
  const setLocale = (e, locale) => {
    e.preventDefault()
    setLocaleMenu(false)
    changeLocale(locale)
  }
  return (
    <div className={layoutStyles.container}>
      {/*eslint-disable-next-line*/}
      <main className={layoutStyles.main} onClick={closeMenus}>
        {children}
      </main>
      <footer className={layoutStyles.footer}>
        {startMenuOpen && (
          <>
            <section className={layoutStyles.startMenu}>
              <section className={layoutStyles.startMenuLeftColumn}>
                <Link to="/about">
                  <BsFillPersonFill />
                </Link>
                <Link to="/skills">
                  <VscTools />
                </Link>
              </section>
              <section className={layoutStyles.placeholder}>
                <p>{intl.formatMessage({ id: "layout__blog_placeholder" })}</p>
              </section>
            </section>
            <section className={layoutStyles.mobileMenu}>
              <a
                href="https://github.com/belabrockmann"
                title={intl.formatMessage({ id: "layout__github" })}
                target="_blank"
                rel="noreferrer"
                aria-label={intl.formatMessage({ id: "layout__github" })}
                className={layoutStyles.smallLink}
              >
                <AiFillGithub />
              </a>
              <Link
                to="/todo"
                className={layoutStyles.smallLink}
                title={intl.formatMessage({ id: "todo__title" })}
              >
                <AiOutlineUnorderedList />
              </Link>
              <Link
                to="/privacy-policy"
                className={layoutStyles.smallLink}
                title={intl.formatMessage({ id: "privacy-policy__title" })}
              >
                <BiShieldQuarter />
              </Link>

              <button
                onClick={() => setLocaleMenu(prev => !prev)}
                className={layoutStyles.smallLink}
                id={layoutStyles.locale}
                title={intl.formatMessage({ id: "layout__switch_language" })}
              >
                <span>
                  <GrLanguage />
                </span>{" "}
                <span>{intl.locale.toUpperCase()}</span>
              </button>
              {localeMenu && (
                <div className={layoutStyles.localeMenu}>
                  <button onClick={e => setLocale(e, "de")}>Deutsch</button>
                  <button onClick={e => setLocale(e, "en")}>English</button>
                </div>
              )}
            </section>
          </>
        )}
        <nav className={layoutStyles.nav}>
          <ul className={layoutStyles.ul}>
            <li>
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 472 472"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={layoutStyles.homeButton}
                onClick={() => setStartMenuOpen(prev => !prev)}
                style={{ backgroundColor: startMenuOpen ? "#2f2f2f" : "#222" }}
              >
                <path
                  d="M262 210V60H304.299H362L412 110V210H262Z"
                  stroke="white"
                  strokeWidth="30"
                />
                <path
                  d="M262 258V408H304.299H362L412 358V258H262Z"
                  stroke="white"
                  strokeWidth="30"
                />
                <path
                  d="M60 210V60H102.299H160L210 110V210H60Z"
                  stroke="white"
                  strokeWidth="30"
                />
                <path
                  d="M60 262V412H102.299H160L210 362V262H60Z"
                  stroke="white"
                  strokeWidth="30"
                />
                <path d="M60 209V261" stroke="white" strokeWidth="30" />
                <path d="M262 210V262" stroke="white" strokeWidth="30" />
              </svg>
            </li>
            <li>
              <Link
                to="/"
                title={intl.formatMessage({ id: "layout__home" })}
                className={layoutStyles.link}
                activeClassName={layoutStyles.activeLink}
              >
                <AiFillHome />
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                title={intl.formatMessage({ id: "about__title" })}
                className={layoutStyles.link}
                activeClassName={layoutStyles.activeLink}
              >
                <BsFillPersonFill />
              </Link>
            </li>
            <li>
              <Link
                to="/skills"
                title={intl.formatMessage({ id: "skills__title" })}
                className={layoutStyles.link}
                activeClassName={layoutStyles.activeLink}
              >
                <VscTools />
              </Link>
            </li>
          </ul>
        </nav>
        <section className={layoutStyles.logos}>
          <a
            href="https://github.com/belabrockmann"
            title={intl.formatMessage({ id: "layout__github" })}
            target="_blank"
            rel="noreferrer"
            aria-label={intl.formatMessage({ id: "layout__github" })}
            className={layoutStyles.smallLink}
          >
            <AiFillGithub />
          </a>
          <Link
            to="/todo"
            className={layoutStyles.smallLink}
            title={intl.formatMessage({ id: "todo__title" })}
          >
            <AiOutlineUnorderedList />
          </Link>
          <Link
            to="/privacy-policy"
            className={layoutStyles.smallLink}
            title={intl.formatMessage({ id: "privacy-policy__title" })}
          >
            <BiShieldQuarter />
          </Link>

          <button
            onClick={() => setLocaleMenu(prev => !prev)}
            className={layoutStyles.smallLink}
            id={layoutStyles.locale}
            title={intl.formatMessage({ id: "layout__switch_language" })}
          >
            <span>
              <GrLanguage />
            </span>{" "}
            <span>{intl.locale.toUpperCase()}</span>
          </button>
          {localeMenu && (
            <div className={layoutStyles.localeMenu}>
              <button onClick={e => setLocale(e, "de")}>Deutsch</button>
              <button onClick={e => setLocale(e, "en")}>English</button>
            </div>
          )}
          <div className={layoutStyles.info}>
            <span>{intl.formatTime(date)}</span>
            <span>{intl.formatDate(date)}</span>
          </div>
          <Link className={layoutStyles.backToDesktop} to="/">
            {" "}
          </Link>
          <div className={layoutStyles.overlay}>
            <span>{intl.formatMessage({ id: "layout__overlay" })}</span>
          </div>
        </section>
      </footer>
      <div className={layoutStyles.block}></div>
    </div>
  )
}

export default Layout
